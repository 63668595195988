import React, { useState } from "react";
import './style.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {Link} from "react-router-dom";
import Image from 'react-bootstrap/Image';


function Sidebar(){
    const [active, setActive]=useState(1);
    return(
        <div className="sidebar d-flex justify-content-between flex-column text-white bg-dark py-3 ps-3 pe-5 vh-100">
            <div>
                <a href="" className="p-3 text-white text-decoration-none">                    
                    <Image src="logo-ozin-sinfdo.jpg"  className="text-center" rounded height="75px"/>                    
                </a>
                <hr className="text-white mt-2"></hr>
                <ul className="nav nav-pills flex-column mt-2 ">
                    <li className={active===1 ? 'active nav-item' : 'nav-item'}  onClick={e => setActive(1)}>
                        <Link to="/" className="p-1 text-decoration-none text-white">
                            <i className="bi bi-speedometer2 me-3 fs-4"></i>
                            <span className="fs-4">Dashboard</span>
                        </Link>
                    </li>
                    <li className={active===2 ? 'active nav-item' : 'nav-item'}  onClick={e => setActive(2)}>
                        <Link  to="/search"  className="p-1 text-decoration-none text-white ">
                            <i className="bi bi-people me-3 fs-4"></i>
                            <span className="fs-4">Search</span>
                        </Link>
                    </li>                
                </ul>
            </div>

        </div>
    )
}
export default Sidebar;