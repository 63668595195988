/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getLogin = /* GraphQL */ `
  query GetLogin($username: String, $password: String) {
    getLogin(username: $username, password: $password) {
      status
      code
      description
      __typename
    }
  }
`;
export const getStatusAni = /* GraphQL */ `
  query GetStatusAni($ani: String) {
    getStatusAni(ani: $ani) {
      status
      code
      description
      date
      __typename
    }
  }
`;
export const getDashboard = /* GraphQL */ `
  query GetDashboard($date_since: String, $date_to: String) {
    getDashboard(date_since: $date_since, date_to: $date_to) {
      date
      status
      cantMessages
      source
      messageCarrier
      __typename
    }
  }
`;
