import React from "react";
import Table from "react-bootstrap/Table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ResultDashboard from "./resultDashboard";
import { getDashboard } from "./graphql/queries";
import {API} from 'aws-amplify';
import { Search } from "react-bootstrap-icons";
import { saveAs } from 'file-saver';
import XLSX from 'sheetjs-style';
import Excel from 'exceljs';



const columns = [
  { header: 'Fecha', key: 'date' },
  { header: 'Fuente', key: 'source' },
  { header: 'Estado', key: 'status' },
  { header: 'Mensajes Carrier Utilizados', key: 'messageCarrier' },
  { header: 'Cantidad de Mensajes', key: 'cantMessages' }
];


const workSheetName = 'Worksheet-1';


function Home() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [existData, setExistData] = useState(false);
  const [resultSearch, setResultSearch] = useState([]);
  const [filter, setFilter] = useState("");

  console.log(filter)

  Date.prototype.addHours= function(h){
    var copiedDate = new Date();
    copiedDate.setTime(this.getTime() + (h*60*60*1000)); 
    return copiedDate;
  }
  Date.prototype.getStringFormat= function(){  
    var dateString = this.getDate()+"/"+this.getMonth()+"/"+this.getFullYear()+", "+this.getHours()+":"+this.getMinutes()+":"+this.getSeconds();    
    return dateString;
  }

  const workbook = new Excel.Workbook();
  const saveExcel = async () => {
    try {

      const fileName = "ReporteMensajes";

      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach(column => {
        column.width = column.header.length + 5;
        column.alignment = { horizontal: 'center' };
      });

      // loop through data and add each one to worksheet
      resultSearch.filter((item)=>{                                   
        return filter.toLocaleLowerCase() === '' ? item : item.source ? item.source.toLocaleLowerCase().includes(filter) : ""
      }).forEach(singleData => {
        worksheet.addRow(singleData);
      });

      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, row => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach(singleCell => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('<<<ERRROR>>>', error);
      console.error('Something Went Wrong', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };

  const dashboardGraphql = async (filter) =>{     
    setExistData(false);     
    console.log("2")   

    const dashboardQuery = await API.graphql({ 
        query: getDashboard, 
        variables:  filter 
    });
    console.log(dashboardQuery)
    if (dashboardQuery.data.getDashboard)
    {
      console.log("3")
      setExistData(true);   
      setResultSearch(dashboardQuery.data.getDashboard)
    }
    else{
      console.log("4")
      setExistData(false);                
    }
  }; 

async function dashboardClick(){    
    console.log("1")    
    dashboardGraphql({"date_since":startDate.addHours(-3).toISOString().substring(0, 10) , "date_to":endDate.addHours(-3).toISOString().substring(0, 10)})
}

  return (
    <div>
      <div className="container-fluid">
        <br></br>
        <h2>Reporte de Mensajes</h2>
        <br></br>

        <Form onChange={(e)=>setFilter(e.target.value)}>
          <div className="row">
            <div className="col col-2">
              <Form.Group className="mb-3 ms-6" controlId="formBasicEmail">
                <Form.Label className="me-3">Fecha Desde </Form.Label>
                <DatePicker
                  selected={startDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => setStartDate(date)}
                />
              </Form.Group>
            </div>
            <div className="col col-2">
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label className="me-3">Fecha Hasta </Form.Label>
                <DatePicker
                  selected={endDate}
                  className="form-label"
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => setEndDate(date)}
                />
              </Form.Group>
            </div>
            <div className="col col-1">
              <br></br>
              <Button
                variant="primary"                
                className="me-3 "                
                onClick={() => dashboardClick()}>              
                Buscar
              </Button>
            </div> 
            <div className="col col-5"><br></br></div>
            {existData ?
              <div className="col col-1">
              <br></br>
                <Button
                  variant="warning"                
                  className="me-3 "                
                  onClick={saveExcel}>              
                  Descargar
                </Button>
              </div>:""
            }            
          </div>
          {existData ?
          <div className="row">
            <div className="col col-3">
              <Form.Group className="mb-3 ms-6">
                <Form.Label className="me-3">Filtrar Fuente </Form.Label>
                <Form.Control
                  className="form"
                  placeholder="ingrese la Fuente a filtrar" 
                  onChange={(e)=> setFilter(e.target.value)}                 
                />
              </Form.Group>
            </div>            
          </div>:""}

          {existData ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Fuente</th>
                  <th>Estado</th>
                  <th>Mensajes Carrier Utilizados</th>
                  <th>Cantidad de Mensajes</th>
                </tr>
              </thead>
              <tbody>
                {resultSearch.filter((item)=>{                                   
                  return filter.toLocaleLowerCase() === '' ? item : item.source ? item.source.toLocaleLowerCase().includes(filter) : ""
                }).map((data, index) => {
                  return (
                    <ResultDashboard
                      date={data.date}
                      source={data.source}
                      state={data.status}
                      messageCarrier={data.messageCarrier}
                      messageCount={data.cantMessages}
                    />
                  );
                })}
              </tbody>
            </Table>
          ) : (
            ""
          )}
        </Form>
      </div>
    </div>
  );
}
export default Home;
