import Table from 'react-bootstrap/Table';

function resultSearch({ani, date, state}) {
    return (
      <Table striped bordered hover>        
        <thead>
          <tr>
            <th>#</th>
            <th>Ani</th>
            <th>Fecha Mensaje</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>{ani}</td>
            <td>{date}</td>
            <td>{state}</td>
          </tr>        
        </tbody>
      </Table>
    );
  }
  
  export default resultSearch;