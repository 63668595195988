import Table from 'react-bootstrap/Table';

function ResultDashboard({date, source, state, messageCarrier, messageCount}) {
    return (    
          <tr>
            <td>{date}</td>
            <td>{source}</td>
            <td>{state}</td>
            <td>{messageCarrier}</td>
            <td>{messageCount}</td>
          </tr>                
    );
  }
  
  export default ResultDashboard;