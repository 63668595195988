import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import { useState } from "react";
import ResultSearch from "./result.js";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { getStatusAni } from "./graphql/queries";

function SearchPage() {
  const navigate = useNavigate();
  const [ani, setAni] = useState("");
  const [result, setResult] = useState([]);
  const [existData, setExistData] = useState(false);

  const callIntertron = async (callingIntertron) => {
    console.log("Hacer llamada a api");
    getStatusAniGraphql({ ani: ani });
    setExistData(true);
  };
  const logout = (event) => {
    console.log("Salir");
    localStorage.removeItem("user-info");
    navigate("/");
    window.location.reload(true);
  };

  const getStatusAniGraphql = async (ani) => {
    setResult({
      ani: ani.ani,
      date: "",
      state: "Estamos buscando los datos. espere por favor",
    });
    try {
      const userQuery = await API.graphql({
        query: getStatusAni,
        variables: ani,
      });
      console.log(userQuery);
      console.log(userQuery.data.getStatusAni);
      setResult({
        ani: ani.ani,
        date: userQuery.data.getStatusAni.date,
        state: userQuery.data.getStatusAni.description,
      });
      setExistData(true);
    } catch (error) {
      setResult({
        ani: ani.ani,
        date: "",
        state:
          "Los datos estan demorando... intenta nuevamente en unos minutos",
      });
    }
  };

  return (
    <Form>
      <div className="container-fluid">
        <br></br>
        <h2>Buscar Telefono</h2>
        <br></br>
        <Stack direction="horizontal" gap={3}>
          <Form.Control
            className="me-auto"
            placeholder="Nro Telefono"
            onChange={(e) => setAni(e.target.value)}
          />
          <Button variant="secondary" onClick={callIntertron}>
            Buscar
          </Button>
          <Button variant="danger" onClick={() => logout()}>
            Salir
          </Button>
        </Stack>
        <br></br>
        {existData ? (
          <ResultSearch
            ani={result.ani}
            state={result.state}
            date={result.date}
          ></ResultSearch>
        ) : (
          ""
        )}
      </div>
    </Form>
  );
}

export default SearchPage;
