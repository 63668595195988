import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import {API, graphqlOperation, Amplify} from 'aws-amplify';
import {getLogin} from './graphql/queries';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import Stack from 'react-bootstrap/Stack';

function Login() {

  const navigate = useNavigate();
  const [email, setEmail]= useState();
  const [password, setPassword]= useState();  
  const [loginError, setLoginError]= useState();  


 const loginGraphql = async (user) =>{     
    setLoginError(false);        

    const userQuery = await API.graphql({ 
        query: getLogin, 
        variables:  user 
    });
    if (userQuery.data.getLogin.status=="Ok")
    {
        localStorage.setItem("user-info", JSON.stringify(userQuery.data.getLogin))
        navigate("/")
        window.location.reload();
    }
    else{
        setLoginError(true);                
    }
  }; 


async function loginClick(){    
    loginGraphql({"username":email , "password":password})
}

  return (
    <Stack gap={1} className="col-md-5 mx-auto">
        <br></br><br></br><br></br>
    <Form>
        <div className="text-center">            
            <Image src="logo-ozin-sinfdo.jpg"  className="text-center" rounded />
        </div>
        
        <br></br><br></br><br></br>

        <h1><Form.Label className='lg-3'>Login</Form.Label></h1>
        
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Usuario</Form.Label>
            <Form.Control type="text" placeholder="Ingrese su usuario"  onChange={(e)=> setEmail(e.target.value)}/>
            
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control type="password" placeholder="Contraseña" onChange={(e)=> setPassword(e.target.value)}/>            
        </Form.Group>
    
        <Button variant="primary"  onClick={loginClick}>
            Iniciar Sesion
        </Button>
        {loginError?<Alert key="warning" variant="warning">Usuario o password incorrecto</Alert>:""}        
    </Form>
    </Stack>
  );
}

export default Login;