import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./login.js";
import SearchPage from "./search";

import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Home from "./Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { useEffect, useState } from "react";

function App() {
  const [toggle, setToggle] = useState(false);

  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
      if (window.innerWidth > 768) {
        setToggle(false);
      }
      window.addEventListener("resize", handleSize);

      return () => {
        window.removeEventListener("resize", handleSize);
      };
    };
  }, []);

  var isLogued = false;
  const userData = localStorage.getItem("user-info");

  if (userData != null) {
    const userInfo = JSON.parse(localStorage.getItem("user-info"));
    if (userInfo.description === "Usuario Encontrado") {
      isLogued = true;
    }
  }
  //const isLogued=localStorage.getItem('user-info')?true:false;

  //const asd=JSON.parse(localStorage.getItem('user-info'));
  //console.log(asd)
  //console.log(asd.description)
  //if(asd.description==="Usuario Encontrado")
  //{
  //  console.log("es igual");
  //}

  return (
    <BrowserRouter>
      {isLogued ? (
        <div className="d-flex">
          <div className={toggle ? "d-none" : "w-auto position-fixed"}>
            <Sidebar />
          </div>
          <div className={toggle ? "d-none" : "invisible"}>
            <Sidebar />
          </div>
          <div className="col overflow-auto">
            <Navbar Toggle={Toggle}></Navbar>
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/search" element={<SearchPage/>}></Route>              
            </Routes>
          </div>
        </div>
      ) : (
        <Login></Login>
      )}
    </BrowserRouter>

    // <div>

    //   <br></br><br></br><br></br>

    //   <Stack gap={1} className="col-md-5 mx-auto">

    //     {isLogued?<SearchPage></SearchPage> : <Login></Login>}

    //   </Stack>
    // </div>
  );
}

export default App;
